.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container-fluid {
  overflow-y : hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: fadeIn 10s;
    -webkit-animation: fadeIn 10s;
    -moz-animation: fadeIn 10s;
    -o-animation: fadeIn 10s;
    -ms-animation: fadeIn 10s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notificationWrapper {
  @apply flex flex-row bg-white rounded-lg border-gray-300 border p-3 shadow-lg;
}


.contentWrapper {
  @apply flex flex-col items-start cursor-default;
}

.contentWrapper h1 {
  @apply text-base text-green-500 font-semibold leading-none tracking-wider;
}

.contentWrapper p {
  @apply text-sm text-green-500 mt-2 leading-relaxed tracking-wider;
}

